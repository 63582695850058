import { styled } from "styled-components";
import { colors } from "../../styles/colors";

export const ContainerProjects = styled.div`
  max-width: 90%;
  margin: 0 auto;
  padding-top: 50px;

  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

export const InfoBlock = styled.div`
  margin-bottom: 2em;
  text-align: center;
  div {
    margin: 0 auto;
    text-align: center;
  }
`;

export const Date = styled.div`
  padding: 0 10px;
  margin-bottom: 2em;
  color: ${colors.DATE_TEXT};
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 30px;

  span {
    font-size: 40px;
    background: linear-gradient(135deg, #000000 0%, #ffe5ae 100%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }
`;

export const WorkExperience = styled.div`
  overflow: auto;
  height: auto;
`;

export const ContentProjectContainer = styled.div`
  /* overflow: hidden;
  height: 100vh; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    overflow: auto;
    height: auto;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 468px) {
    overflow: auto;
    height: auto;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ProjectContent = styled.div`
  padding: 0 10px;
  margin-bottom: 2em;
`;

export const Subtitle = styled.h3`
  font-weight: 200;
  font-size: 22px;
  text-align: center;
  margin-bottom: 0;
  color: ${colors.SUBTITLE_TEXT};
  text-decoration: none;
`;

export const ContainerImage = styled.div`
  text-align: center;

  img {
    width: 100%;
    height: 200px;
  }

  img:hover {
    opacity: 0.4;
  }

  @media (max-width: 768px) {
    img {
      max-width: 250px;
    }
  }
`;

export const ContainerTechs = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
  justify-content: space-evenly;

  div {
    text-align: center;
  }

  img {
    width: 25px;
    height: auto;
    margin: 0 3px;
  }

  p {
    margin-top: 2px;
    font-size: 11px;
    color: ${colors.TEXT_GREY_NEUTRAL};
  }
`;

export const Description = styled.div`
  margin-top: -4px;
  color: ${colors.DESC_TEXT};
  padding: 12px;
  text-align: justify;
`;

export const ContainerAvatar = styled.div`
  text-align: center;
  max-width: 250px;

  img {
    width: 250px;
    background: ${colors.TEXT_GRADIENT_PURPLE};
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }
`;

export const ContactData = styled.div`
  margin-top: -4px;
  color: #000000;
  padding: 12px;
  text-align: justify;
  height: 100px;
`;

export const Divider = styled.div`
  background-color: ${colors.DIVIDER};
  width: 60%;
  height: 2px;
  margin: 0 auto;
`;
