import { useState } from "react";
import * as S from "./Navbar.styles";

const Navbar = () => {

  const [showMenuBurguer, setShowMenuBurguer] = useState(false);

  const clickItemNav = item => {
    document.getElementById(item).scrollIntoView({behavior: "smooth"});
  };
  return (
    <S.ContainerNavbar>
      <S.ContainerLogo>
        <S.ImageLogo alt="Alexia Torres" src="/img/logo.png" />
        <div>
          <h1>Alexia Torres</h1>
          <h2>Web Developer</h2>
        </div>
      </S.ContainerLogo>
      <S.ContainerBurguer onClick={() => setShowMenuBurguer(!showMenuBurguer)}>
        <S.MenuBurguer />
        <S.MenuBurguer />
        <S.MenuBurguer />
      </S.ContainerBurguer>
      <S.ContainerItems $showMenuBurguer={showMenuBurguer}>
        <p onClick={() => clickItemNav("about-me")}>About me</p>
        <p onClick={() => clickItemNav("projects")}>Projects</p>
        <S.Button onClick={() => clickItemNav("contact")}>Contact me 🙈</S.Button>
      </S.ContainerItems>
    </S.ContainerNavbar>
  );
};

export default Navbar;
