import React from "react";
import * as S from "./AboutMe.styles";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

const AboutMe = () => {
  const socialNetworks = [
    {
      name: "Gitlab",
      link: "https://gitlab.com/AlexiaTorres",
      logo: "/svg/gitlab.svg",
    },
    {
      name: "Github",
      link: "https://github.com/AlexiaTorres",
      logo: "/svg/github.svg",
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/in/alexia-torres-4a376192/",
      logo: "/svg/linkedin.svg",
    },
  ];

  return (
    <S.ContainerAboutMe id="about-me">
      <S.ContainerFlex>
        <S.ContainerText>
          <S.Title>
            <p>👋🏼 Hi! I'm</p>
            <AnimationOnScroll animateIn="animate__fadeInLeftBig">
              <p>
                {" "}
                <span className="gradient-name">Alexia Torres </span>{" "}
              </p>
              <p>Web Developer</p>
            </AnimationOnScroll>
          </S.Title>
          <S.DescriptionText>
            I love my profession because it's a continuous learning. With or without a job there will always be work and
            something new to learn 🙊
            <br/>
            I work with frontend and backend, most of the time my job has been fullstack,
            but if there is something I'm passionate about it is frontend, I'm a very creative, proactive and happy person. 
            <br/>
            Apart from being a
            programmer, I'm a person and I like to paint warhammer figures and
            the world of beauty and fashion. I'm very geek, but also a diva 💅🏻
            <br/>
            Also I like languages ​​and I learn them very easily. At the moment my languages ​​are Spanish, French, English and Italian.
            <br/>
            I have some public personal projects in git and in linkedin my professional career and education.
            <br/>
          </S.DescriptionText>
          <S.ContainerIcons>
            {socialNetworks.map((network, index) => (
              <a
                key={index}
                href={network.link}
                target="__blank"
                rel="noreferrer"
              >
                <img src={network.logo} alt={network.name} />
              </a>
            ))}
          </S.ContainerIcons>
        </S.ContainerText>
        <S.ContainerImage>
          <img src="/img/memoji.png" alt="Alexia Torres" />
        </S.ContainerImage>
      </S.ContainerFlex>
    </S.ContainerAboutMe>
  );
};

export default AboutMe;
