import AboutMe from "../AboutMe";
import Container from "../Container";
import Navbar from "../Navbar/Navbar";
import Projects from "../Projects/Projects";
import Contact from "../Contact/Contact";
import Skills from "../Skills";

export default function Portfolio() {
  return (
    <>
      <Navbar />
      <Container>
        <AboutMe />
        <Skills />
        <Projects />
        <Contact />
      </Container>
    </>
  );
}
