import * as S from "./CV.styles";
import { worksCV } from "./CVData";

export default function CV() {
  return (
    <S.ContainerProjects id="cv">
      <S.ContentProjectContainer>
        <S.InfoBlock>
          <S.Title>Alexia Torres</S.Title>
          <S.ContainerAvatar>
            <img src="/img/yo.png" alt="Alexia Torres" />
          </S.ContainerAvatar>
          <S.ContactData>
            📱 611 489 220 <br />
            ✉️ alexia11work@gmail.com <br />
            📍 Cáceres
          </S.ContactData>

          <div>
            <S.Title>Educación</S.Title>
            <S.Subtitle>F.P.II - DAW</S.Subtitle>
            <S.Description>
              IES MARIA DE ZAYAS Y SOTOMAYOR
              <S.Date>2013-2015</S.Date>
            </S.Description>
          </div>
          <div>
            <S.Subtitle>BACHILLERATO - CIENCIAS</S.Subtitle>
            <S.Description>
              IES ALFONSO MORENO
              <S.Date>2009-2011</S.Date>
            </S.Description>
          </div>

          <div>
            <S.Title>Otros títulos</S.Title>
            <S.Subtitle>Desarrollo de apps moviles - UCM</S.Subtitle>
            <S.Subtitle>Italiano A2</S.Subtitle>
            <S.Subtitle>DELF B1</S.Subtitle>
            <S.Subtitle>Tatuaje Profesional</S.Subtitle>
            <S.Subtitle>Social Media Management</S.Subtitle>
            <S.Subtitle>Social Media 2.0 & Social Media Strategy</S.Subtitle>
          </div>

          <div>
            <S.Title>IDIOMAS</S.Title>
            <S.Subtitle>Español - Nativo</S.Subtitle>
            <S.Subtitle>Inglés - B2 (reading y writing)</S.Subtitle>
            <S.Subtitle>Francés - B1 </S.Subtitle>
            <S.Subtitle>Italiano - A2</S.Subtitle>
          </div>
        </S.InfoBlock>

        <S.WorkExperience>
          <S.Title>Experiencia Laboral</S.Title>
          {worksCV.map((work, index) => (
            <S.ProjectContent key={index}>
              <S.Subtitle>{work.title}</S.Subtitle>
              <S.Date>{work.date}</S.Date>
              <S.Description>{work.description}</S.Description>
              <S.ContainerTechs>
                {work.techs.map((tech, index) => (
                  <div key={index}>
                    <img src={tech.src} alt={tech.name} />
                    <p>{tech.name}</p>
                  </div>
                ))}
              </S.ContainerTechs>
              <S.Divider></S.Divider>
            </S.ProjectContent>
          ))}
        </S.WorkExperience>
      </S.ContentProjectContainer>
    </S.ContainerProjects>
  );
}
