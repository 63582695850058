export const colors = {
  TEXT_GRADIENT_PURPLE: "linear-gradient(135deg,#cb5eee 0%,#4be1ec 100%)",
  TEXT_GRADIENT_GREEN: "linear-gradient(135deg,#d6ff7f 0%,#00b3cc 100%)",
  TEXT_GREY_NEUTRAL: "#6b6f76",
  TEXT_BLACK: "#1d2238",
  TEXT_WHITE: "#fff",

  BACKGROUND_GRADIENT_PINK:
    "linear-gradient( 135deg, rgb(102,153,255) 0%, rgb(255,51,102) 100% )",

  BOX_SHADOW: "0 0 20px rgba(0, 0, 0, 0.04)",
  BOX_SHADOW_BLUE: " -3px 4px 0 rgb(102 153 255)",
  BOX_SHADOW_BUTTON: "0 10px 20px -10px #b664b0",

  DATE_TEXT: "#8da3db",
  SUBTITLE_TEXT: "#767676",
  DESC_TEXT: "#767676",
  DIVIDER: "#abbcca",
};
