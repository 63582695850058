export const worksCV = [
  {
    title: "OMLC Nelumbo",
    date: "05/2022 - 09/2023", 
    techs: [
      { name: "PHP", src: "/img/techs/php.png" },
      { name: "CSS", src: "/img/techs/css.png" },
      { name: "Laravel", src: "/img/techs/laravel.png" },
      { name: "React ", src: "/img/techs/react.png" },
    ],
    description:
      "App de cero con vue y laravel. mantenimiento de otras cosas. Ir añadiendo funcionalidades. Cambiar proyecto de php puro a react yo con el front. Proyecto con react y firebase.",
  },
  {
    title: "Pampling",
    date: "10/2021 - 12/2021",
    techs: [
      { name: "PHP", src: "/img/techs/php.png" },
      { name: "CSS", src: "/img/techs/css.png" },
      { name: "Laravel", src: "/img/techs/laravel.png" },
      { name: "VUE", src: "/img/techs/vue.png" },
      { name: "SASS", src: "/img/techs/sass.png" },

    ],
    description:
      "Mantenimiento, pasar una app de prestashop a vue en el front, alguna ayuda en el back en laravel. Php puro algunas funcionalidades de incidencias.",
  },
  {
    title: "Higia Benchmarking",
    date: "08/2018 - 11/2021",
    techs: [
      { name: "CSS", src: "/img/techs/css.png" },
      { name: "React ", src: "/img/techs/react.png" },
      { name: "Laravel", src: "/img/techs/laravel.png" },
      { name: "VUE", src: "/img/techs/vue.png" },
      { name: "SASS", src: "/img/techs/sass.png" },
      { name: "JQuery", src: "/img/techs/jquery.png" },
      { name: "Styled Components", src: "/img/techs/styled-logo.png" },

    ],
    description:
      "Proyecto de cero en vue , incidencias , app en react native, maquetacion en react de un programa. cms y landings y cmr. react, react native, sass, css, laravel, php, javascript "
    },
  {
    title: "Accem",
    date: "08/2018 - 11/2021",
    techs: [
      { name: "Wordpress", src: "/img/techs/wordpress.png" },
      { name: "CSS", src: "/img/techs/css.png" },
    ],
    description: "Front en vue, alguna incidencia en php o laravel",
  },
  {
    title: "Avirato",
    date: "01/2018 - 04/2018",
    techs: [
      { name: "Styled Components", src: "/img/techs/styled-logo.png" },
      { name: "React ", src: "/img/techs/react.png" },
    ],
    description:
      "Laravel en back e incidencias y añadidos en react en front.",
  },
  {
    title: "Innovega",
    date: "05/2017 - 11/2017",
    techs: [
      { name: "Ruby on rails", src: "/img/techs/rubyonrails.png" },
      { name: "SASS", src: "/img/techs/sass.png" },
      { name: "JS", src: "/img/techs/js.png" },
    ],
    description:
      "Ruby on rails y sass para un proyecto y algun mantenimiento en otros",
  },

    
  {
    title: "Common MS",
    date: "04/2017 - 06/2017",
    techs: [
      { name: "Angular", src: "/img/techs/angular.png" },
      { name: "CSS", src: "/img/techs/css.png" },
      { name: "Spring", src: "/img/techs/spring.png" },
    ],
    description:
      "Mantenimiento y programa nuevo en angular, alguna cosa en java spring",
  },
    
  {
    title: "Nablae",
    date: "05/2014 - 04/2017",
    techs: [
      { name: "React", src: "/img/techs/react.png" },
      { name: "CSS", src: "/img/techs/css.png" },
      { name: "Laravel", src: "/img/techs/laravel.png" },
      { name: "JQuery", src: "/img/techs/jquery.png" },
      { name: "JS", src: "/img/techs/js.png" },
      { name: "VUE", src: "/img/techs/vue.png" },
      { name: "MySQL", src: "/img/techs/mysql.png" },
      { name: "React Router", src: "/img/techs/react-router-dom.png" },
    ],
    description:
      "Varios proyectos sola varios en equipo, proyectos nuevos con php, laravel o react y laravel. Mantenimiento e incidencias. "
  },
   
];
