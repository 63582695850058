import * as S from "./Skills.styles";
import { skillsData } from "./SkillsData";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

const Skills = () => {
  return (
    <S.SkillsContainer>
      <S.Title>Skills</S.Title>
      <div>
        {skillsData.map((skill, index) => (
          <AnimationOnScroll
            initiallyVisible={true}
            animateIn="animate__jello"
            key={index}
          >
            <img src={skill.src} alt={skill.name} title={skill.name}/>
            
          </AnimationOnScroll>
        ))}
      </div>
    </S.SkillsContainer>
  );
};

export default Skills;
