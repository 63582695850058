import { styled } from "styled-components";
import { colors } from "../../styles/colors";

export const Title = styled.h2`
  text-align: center;
  font-size: 30px;
`;

export const SkillsContainer = styled.div`
  padding-top: 10px;
  width: 70%;
  max-width: 900px;
  margin: 10% auto;

  div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
  }

  @media (max-width: 768px) {
    display: block;
    padding: 0px 30px;
  }

  img {
    width: 45px;
    height: auto;
    margin: 20px 30px;
  }

  p {
    margin-top: 2px;
    font-size: 13px;
    text-align: center;
    color: ${colors.TEXT_GREY_NEUTRAL};
  }
`;
