export const skillsData = [
  { name: "PHP ", src: "/img/techs/php.png" },
  { name: "CSS", src: "img/techs/css.png" },
  { name: "React", src: "img/techs/react.png" },
  { name: "Semantic UI React", src: "img/techs/semantic.png" },
  { name: "Styled Components", src: "img/techs/styled-logo.png" },
  { name: "Sass", src: "img/techs/sass.png" },
  { name: "React Bootstrap", src: "img/techs/react-bootstrap.png" },
  { name: "Vite", src: "img/techs/vite.png" },
  { name: "Chakra", src: "img/techs/chakra.png" },
  { name: "React Router DOM", src: "img/techs/react-router-dom.png" },
  { name: "Angular", src: "img/techs/angular.png" },
  { name: "Codeigniter", src: "img/techs/codeigniter.png" },
  { name: "Jquery", src: "img/techs/jquery.png" },
  { name: "JS", src: "img/techs/js.png" },
  { name: "Less", src: "img/techs/less.png" },
  { name: "MySQL", src: "img/techs/mysql.png" },
  { name: "React Bootstrap", src: "img/techs/react-bootstrap.png" },
  { name: "Spring", src: "img/techs/spring.png" },
  { name: "Vue", src: "img/techs/vue.png" },
  { name: "linux", src: "img/techs/linux.png" },
  { name: "macos", src: "img/techs/macos.png" },
  { name: "Wordpress", src: "img/techs/wordpress.png" },
  { name: "windows", src: "img/techs/windows.png" },
  { name: "gitlab", src: "img/techs/gitlab.png" },
  { name: "github", src: "img/techs/github.png" },
  { name: "bitbucket", src: "img/techs/bitbucket.png" },
  { name: "jira", src: "img/techs/jira.png" },
  { name: "trello", src: "img/techs/trello.png" },
  { name: "node", src: "img/techs/node.png" },
  { name: "elm", src: "img/techs/elm.png" },
  { name: "html", src: "img/techs/html.png" },
  { name: "canva", src: "img/techs/canva.png" },
  { name: "elementor", src: "img/techs/elementor.png" },
  { name: "rubyonrails", src: "img/techs/rubyonrails.png" },
  { name: "bootstrap", src: "img/techs/bootstrap.png" },
  { name: "netlify", src: "img/techs/netlify.png" },
];