import React, { useRef, useState } from "react";
import * as S from "./Contact.styles";
import emailjs from "@emailjs/browser";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

const Contact = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    message: "",
    checkedTerms: false,
  });

  const [emptyInputError, setEmptyInputError] = useState(false);
  const [successSend, setSuccessSend] = useState(false);
  const [errorSend, setErrorSend] = useState(false);

  const form = useRef();

  const onSubmit = (e) => {
    //evitar que se recargue la página
    e.preventDefault();
    //validar formulario
    if (
      contactData.name === "" ||
      contactData.email === "" ||
      contactData.message === "" ||
      contactData.checkedTerms === false
    ) {
      setEmptyInputError(true);
    }
    //enviar formulario
    else {
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_EMAILJS_PUBLIC_USER_ID
        )
        .then(
          (result) => {
            setSuccessSend(true);
          },
          (error) => {
            setErrorSend(true);
          }
        );
    }
  };

  return (
    <S.ContainerContact id="contact">
      <AnimationOnScroll animateIn="animate__fadeInLeftBig">
        <S.Title>Contact with me 🙈</S.Title>
      </AnimationOnScroll>
      <S.ContainerForm>
        {successSend ? (
          <S.SucessMessage>Form was sent succesfully</S.SucessMessage>
        ) : (
          <S.Form onSubmit={onSubmit} ref={form}>
            <S.InputForm
              placeholder="Your name"
              type="text"
              name="name"
              onChange={(e) =>
                setContactData({ ...contactData, name: e.target.value })
              }
              value={contactData.name}
            />
            <S.InputForm
              placeholder="Your email"
              type="email"
              name="email"
              onChange={(e) =>
                setContactData({ ...contactData, email: e.target.value })
              }
              value={contactData.email}
            />
            <S.TextArea
              placeholder="Write your message"
              name="message"
              onChange={(e) =>
                setContactData({ ...contactData, message: e.target.value })
              }
              value={contactData.message}
            />
            <S.ContainerConditions>
              <input
                type="checkbox"
                onClick={(e) =>
                  setContactData({
                    ...contactData,
                    checkedTerms: e.target.checked,
                  })
                }
              />
              <span>Accept terms and conditions</span>
            </S.ContainerConditions>
            <S.Button type="submit">Send</S.Button>
          </S.Form>
        )}
        {emptyInputError && (
          <S.TextError>
            You should fill all fields and accept terms and conditions
          </S.TextError>
        )}
        {errorSend && (
          <S.TextError> There was an error, try later again.</S.TextError>
        )}
      </S.ContainerForm>
    </S.ContainerContact>
  );
};

export default Contact;
