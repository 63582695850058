export const projectsPortfolio = [
  {
    title: "EditPDF",
    image: "/img/projects/editpdf.png",
    techs: [
      { name: "PHP", src: "/img/techs/php.png" },
      { name: "CSS", src: "/img/techs/css.png" },
      { name: "Laravel", src: "/img/techs/laravel.png" },
    ],
    description:
      "File converter and PDF editor, made with php and migrated to laravel",
    repository: "",
    website: "https://editpdfonline.mobi/es",
  },
  {
    title: "Marvel API",
    image: "/img/projects/marvel.jpg",
    techs: [
      { name: "React", src: "/img/techs/react.png" },
      { name: "React Router ", src: "/img/techs/react-router-dom.png" },
      { name: "Sass", src: "/img/techs/sass.png" },
      { name: "Semantic", src: "/img/techs/semantic.png" },
    ],
    description:
      "Application created with React JS, React Router and Sass to make a request from external Marvel API.",
    repository: "https://github.com/AlexiaTorres/marvel-react",
    website: "https://64cba750b759fc75e962cbe2--sunny-mermaid-8f7bf9.netlify.app/",
  },
  {
    title: "Patient Portal CMMS",
    image: "/img/projects/cmms.png",
    techs: [
      { name: "CSS", src: "/img/techs/css.png" },
      { name: "Angular", src: "/img/techs/angular.png" },
      { name: "Spring", src: "/img/techs/spring.png" },
    ],
    description:
      "Internal application for patients who can consult their medical records. Made with angular and springboot",
    repository: "",
    website: "#!",
  },
  {
    title: "Génesis Cocinas",
    image: "/img/projects/genesis.png",
    techs: [
      { name: "Wordpress", src: "/img/techs/wordpress.png" },
      { name: "CSS", src: "/img/techs/css.png" },
    ],
    description: "Kitchen furniture website, made with wordpress and css",
    repository: "",
    website: "http://www.genesiscocinas.com/",
  },
  {
    title: "Campaña educacional",
    image: "/img/projects/fss.png",
    techs: [
      { name: "Laravel", src: "/img/techs/laravel.png" },
      { name: "VUE", src: "/img/techs/vue.png" },
      { name: "SASS", src: "/img/techs/sass.png" },
    ],
    description:
      "Educational campaign to prevent addictions in adolescents. Internal course manager, with different roles, made with laravel and sass",
    repository: "",
    website: "",
  },
  {
    title: "ACF Consultores",
    image: "/img/projects/acf.png",
    techs: [
      { name: "Codeigniter", src: "/img/techs/codeigniter.png" },
      { name: "LESS", src: "/img/techs/less.png" },
      { name: "JS", src: "/img/techs/js.png" },
    ],
    description:
      "Web of tax consultancies, with internal clients area. Made with codeigniter and css",
    repository: "",
    website: "https://www.acf-consultores.com/",
  },
  {
    title: "Rick & Morty API",
    image: "/img/projects/rick-morty.jpg",
    techs: [
      { name: "React ", src: "/img/techs/react.png" },
      { name: "CSS", src: "/img/techs/css.png" },
    ],
    description:
      "Application created with React JS and CSS to make a request from external Rick & Morty API.",
    repository: "https://github.com/AlexiaTorres/rick-morty/",
    website: "https://frabjous-nougat-c85b81.netlify.app/",
  },
  {
    title: "Lists Todo",
    image: "/img/projects/todo.jpg",
    techs: [
      { name: "React ", src: "/img/techs/react.png" },
      { name: "Semantic UI React", src: "/img/techs/semantic.png" },
    ],
    description:
      "Application created with React JS and Semantic UI to make a list of tasks where you can add or remove it.",
    repository: "",
    website: "https://64cba8c077969a78605ce69c--polite-dango-9e9467.netlify.app/",
  },
  {
    title: "Simpson API",
    image: "/img/projects/simpson.jpg",
    techs: [
      { name: "React JS ", src: "/img/techs/react.png" },
      { name: "Styled Components", src: "/img/techs/styled-logo.png" },
    ],
    description:
      "Application created with React JS and Styled Components to make a request from external Simpsons API.",
    repository: "https://github.com/AlexiaTorres/simpson-phrases",
    website: "https://64cba916bcf93d7a17c3cd76--illustrious-wisp-db6277.netlify.app/",
  },
  {
    title: "Higia Benchmarking",
    image: "/img/projects/higia.png",
    techs: [
      { name: "CSS", src: "/img/techs/css.png" },
      { name: "Laravel", src: "/img/techs/laravel.png" },
      { name: "JQueryY", src: "/img/techs/jquery.png" },
      { name: "JS", src: "/img/techs/js.png" },
      { name: "VUE", src: "/img/techs/vue.png" },
      { name: "MySQL", src: "/img/techs/mysql.png" },
    ],
    description:
      "Web, multiple CMS, landing and news blog. Very complete professional project, where there has been use of laravel, vue, javascript, jquery, css",
    repository: "",
    website: "https://benchmarking30.com/",
  },
  {
    title: "Dynamic Form",
    image: "/img/projects/dynamic.jpg",
    techs: [
      { name: "React JS", src: "/img/techs/react.png" },
      { name: "React Bootstrap", src: "/img/techs/react-bootstrap.png" },
    ],
    description:
      "Landing page created with React JS and React Bootstrap to make a form with dynamic fields.",
    repository: "https://github.com/AlexiaTorres/dynamic-form-project",
    website: "https://64cba96777969a78605ceb31--dulcet-sawine-fa00c0.netlify.app/",
  },
  {
    title: "Fuel Station",
    image: "/img/projects/gasolinera.jpg",
    techs: [
      { name: "Vite", src: "/img/techs/vite.png" },
      { name: "Chakra", src: "/img/techs/chakra.png" },
      { name: "React Router DOM", src: "/img/techs/react-router-dom.png" },
    ],
    description:
      "Application created with Vite where you can save the information and see it on a summary",
    repository: "https://github.com/AlexiaTorres/gasolinera-react-vite",
    website: "https://64cbaaa59088c87a6201b2c0--relaxed-selkie-47f9c0.netlify.app/",
  },
];
