import React from "react";
import * as S from "./Projects.styles";
import { projectsPortfolio } from "./ProjectsData";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

const Projects = () => {
  return (
    <S.ContainerProjects id="projects">
      <AnimationOnScroll animateIn="animate__fadeInLeftBig">
        <S.Title>Some personal and profetional projects</S.Title>
      </AnimationOnScroll>
      <S.ContentProjectContainer>
        {projectsPortfolio.map((project, index) => (
          <S.ProjectContent key={index}>
            <S.TitleProject>{project.title}</S.TitleProject>
            <S.ContainerImage>
              <a href={project.website} target="_blank">
                <img src={project.image} alt={project.title} lazy="true" />
              </a>
            </S.ContainerImage>
            <S.Description>{project.description}</S.Description>
            <S.ContainerTechs>
              {project.techs.map((tech, index) => (
                <div key={index}>
                  <img src={tech.src} alt={tech.name} />
                  <p>{tech.name}</p>
                </div>
              ))}
            </S.ContainerTechs>
          </S.ProjectContent>
        ))}
      </S.ContentProjectContainer>
    </S.ContainerProjects>
  );
};

export default Projects;
